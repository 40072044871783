<template>
  <div class="toast show" :id="'taskID_' + task.id" :style="'background:' + task.type.custom_color.replace('0.25', '0.1')">
    <div class="toast-body">
      <task-item-main-block :task="task"/>

      <div class="mt-2">
        <span class="label label-default text-white font-medium pull-right"
              :style="'padding: 2px 6px; font-size: 14px; background:' + task.type_status.custom_color.replace('0.25', '1')">
              {{ task.type_status.name }}
        </span>
      </div>

      <task-item-comment-block v-if="task.type_id === 1 && task.last_comment"
                               :task_last_comment="task.last_comment"/>

      <task-item-client-block v-if="task.client" :task_client="task.client" :task_id="task.id" :is_called="task.details.is_called ?1:0"/>

      <task-item-details-block v-if="Object.keys(task.details).length"
                               :task_details="task.details"
                               :task_car_price="task.car ? task.car.price : 0"
                               :with_delimiter="(task.type_id !== 1 && task.last_comment && task.last_comment.length)"/>

      <task-item-comment-block v-if="task.type_id !== 1 && task.last_comment" :task_last_comment="task.last_comment"
                               :with_delimiter="false"/>
    </div>

    <div class="toast-header" :style="'background:' + task.type.custom_color.replace('0.25', '0.1')">
      <button v-if="[1, 2, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)"
              class="btn btn-primary pull-right"
              title="Назначить ответственного"
              type="button"
              v-on:click="showEditTaskResponsibleModal(task.id)">
        <i class="fas fa-user-plus"></i>
      </button>

      <router-link :to="{ name: 'task.details', params: { taskId: task.id, currentTab: 'История' }}"
                   class="btn btn-success margin-l-5"
                   title="Детали"
                   type="button">
        <i class="fa fa-info-circle"></i>
      </router-link>

      <button v-if="[1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)"
              class="btn btn-warning pull-right margin-l-5"
              title="В архив"
              type="button"
              v-on:click="removeTask(task.id)">
        <i class="fa fa-trash"></i>
      </button>
    </div>

    <modal :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'edit_task_responsible_modal_' + task.id"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4>Выбрать ответственного для заявки #{{ task.id }}</h4>
        </div>

        <div class="box-body">
          <edit-task-responsible-form :task="task"></edit-task-responsible-form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VModal from 'vue-js-modal';
import VuejsDialog from 'vuejs-dialog';
import Edit from "@/views/cars/edit";
import EditTaskResponsibleForm from "@/views/tasks/details/edit-responsible-form";

Vue.use(VModal);
Vue.use(VuejsDialog);

Vue.component('task-item-main-block', (resolve) => {
  return require(['./../../item_blocks/main-block'], resolve);
});
Vue.component('task-item-client-block', (resolve) => {
  return require(['./../../item_blocks/client-block'], resolve);
});
Vue.component('task-item-details-block', (resolve) => {
  return require(['./../../item_blocks/details-block.vue'], resolve);
});
Vue.component('task-item-comment-block', (resolve) => {
  return require(['./../../item_blocks/comment-block'], resolve);
});

Vue.component('edit-responsible-form', (resolve) => {
  return require(['./../../details/edit-responsible-form'], resolve);
});

export default {
  name: 'task-new-item',

  components: {EditTaskResponsibleForm, Edit},

  props: {
    task: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  data: () => ({}),

  computed: {},

  methods: {
    async showEditTaskResponsibleModal(taskId) {
      this.$modal.show('edit_task_responsible_modal_' + taskId);
    },
    removeTask(taskId) {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/tasks/' + taskId).then(() => {
          setTimeout(() => {
            document.getElementById('taskID_' + taskId).style.display = 'none';
          }, 500);
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>


<style scoped>
.btn, a {
  white-space: break-spaces;
}

.toast {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  width: unset;
  box-shadow: none;
  margin-bottom: 15px;
}

.toast-body {
  padding: 8px 8px 10px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.toast-header {
  border-radius: 0;
  border-bottom: 0;
  padding: 8px;
}

.toast-header:hover {
  background: #f4f4f6;
}
</style>
